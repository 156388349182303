<template>
  <div @click="showtype = false">
    <div class="contain1">
      <div class="searchBox" ref="viewBox">
        <el-form
          class="searchBoxForm"
          label-width="90px"
          :inline="true"
          size="small"
        >
          <el-form-item label="签约时间" class="searchItem" label-width="75px">
            <!-- <el-date-picker
              v-model="pageParams.signTime"
              format="yyyy-MM-dd HH:mm"
              type="datetime"
              clearable
              placeholder="选择日期时间"
            >
            </el-date-picker> -->
            <el-date-picker
              size="small"
              v-model="date"
              type="datetimerange"
              format="yyyy-MM-dd HH:mm"
              value-format="yyyy-MM-dd HH:mm"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              @change="onSetDate"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="确认状态" class="searchItem">
            <el-select
              v-model="pageParams.contractConfirmStatus"
              clearable
              placeholder="请选择"
            >
              <el-option
                v-for="item in contractConfirmStatusDic"
                :key="item.code"
                :label="item.label"
                :value="item.code"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <div ref="viewContract">
            <el-form-item label="合同类型" class="searchItem viewContract">
              <el-input
                @click.native.stop="showtype = true"
                v-model="pageParams.contractType"
                placeholder="请选择"
                class="SearchInput"
                clearable
                @clear="clearType"
                readonly
              >
                <template v-slot:suffix @click.stop="clearType">
                  <i
                    @click.stop="clearType"
                    :class="
                      pageParams.contractType
                        ? 'el-icon-circle-close'
                        : 'el-icon-caret-bottom'
                    "
                  ></i>
                </template>
              </el-input>
              <div
                class="searchType"
                :style="style"
                @click.stop="showtype = true"
                v-if="showtype"
              >
                <div style="margin: 10px">
                  <el-radio-group
                    v-model="pageParams.contractTypeParent1"
                    @change="changeChildSearch"
                  >
                    <div v-for="item in options" :key="item.id">
                      <el-radio
                        class="radioList"
                        :label="item.id"
                        :key="item.id"
                        >{{ item.name }}</el-radio
                      >
                      <el-radio-group
                        v-model="pageParams.contractTypeParent2"
                        @change="changeChildSearch2"
                        style="margin-left: 20px"
                        :disabled="parentID1 != item.id ? true : false"
                        v-if="item.children && item.children.length > 0"
                      >
                        <div v-for="itema in item.children" :key="itema.id">
                          <el-radio
                            class="radioList"
                            :label="itema.id"
                            :key="itema.id"
                            >{{ itema.name }}</el-radio
                          >
                          <el-checkbox-group
                            @change="changeLabelSearch"
                            v-model="searchContractTypeChildIdArr"
                            style="margin-left: 20px"
                            :disabled="parentID2 != itema.id ? true : false"
                            v-if="itema.children && itema.children.length > 0"
                          >
                            <el-checkbox
                              v-for="itemc in itema.children"
                              :label="itemc.id"
                              :key="itemc.id"
                              style="width: 140px"
                              >{{ itemc.name }}</el-checkbox
                            >
                          </el-checkbox-group>
                        </div>
                      </el-radio-group>
                    </div>
                  </el-radio-group>
                </div>
              </div>
            </el-form-item>
          </div>

          <el-form-item
            label="甲方/联系电话"
            class="searchItem"
            label-width="120px"
          >
            <el-input
              v-model="pageParams.firstPartyOrPhone"
              placeholder="请输入"
              clearable
            ></el-input>
          </el-form-item>
          <el-button type="primary" size="small" @click="loadList"
            >查询</el-button
          >
        </el-form>
      </div>
    </div>
    <div class="contain2">
      <div class="tableTool">
        <div class="rightButtonTool">
          <el-button type="primary" plain @click="onAddNewContract" size="small"
            >新建合同</el-button
          >
        </div>
      </div>
      <el-table
        :data="tableData"

        border
        height="calc(100vh - 383px)"
        stripe
        :loading="tableLoading"
        :header-cell-style="{ background: '#f8f8f9' }"
      >
        <el-table-column
          type="index"
          :index="indexMethod"
          label="序号"
          align="center"
          width="50"
        >
        </el-table-column>
        <el-table-column
          v-for="(item, index) in tableHeader"
          :key="index"
          :label="item.label"
          :prop="item.property"
          :width="item.width ? item.width : ''"
          align="center"
        >
          <template slot-scope="scope">
            <div v-if="scope.column.property === 'contractConfirmStatus'">
              <el-tag
                v-if="scope.row.contractConfirmStatus == '确认完成'"
                type="success"
                >{{ scope.row.contractConfirmStatus }}</el-tag
              >
              <el-tag v-else-if="scope.row.contractConfirmStatus == '待确认'">{{
                scope.row.contractConfirmStatus
              }}</el-tag>
              <el-tag v-else type="warning">{{
                scope.row.contractConfirmStatus
              }}</el-tag>
            </div>
            <div v-else-if="scope.column.property === 'contractCompleteStatus'">
              <el-tag
                v-if="scope.row.contractCompleteStatus == '已完成'"
                type="success"
                >{{ scope.row.contractCompleteStatus }}</el-tag
              >
              <el-tag v-else type="info">{{
                scope.row.contractCompleteStatus
              }}</el-tag>
            </div>
            <div v-else-if="scope.column.property === 'contractType'">
              {{ scope.row.contractTypeParent || "-" }}
              <span v-if="scope.row.contractTypeChild"
                >({{ scope.row.contractTypeChild }})</span
              >
            </div>
            <div v-else>
              {{ scope.row[scope.column.property] || "-" }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="left" width="200">
          <template slot-scope="scope">
            <div class="operateTool">
              <el-button
                @click="onShowContract(scope.row)"
                size="small"
                type="primary"
                >查看</el-button
              >
              <el-button
                size="small"
                v-if="scope.row.contractConfirmStatus == '待确认'"
                type="primary"
                @click="onUpdataRow(scope.row)"
                >编辑</el-button
              >

              <el-button
                size="small"
                type="success"
                @click="onCompleteContract(scope.row)"
                v-if="
                  scope.row.contractCompleteStatus == '未完成' &&
                  scope.row.contractConfirmStatus == '确认完成'
                "
                >合同完成</el-button
              >
            </div>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        layout="total, prev, pager, next,sizes, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="pageParams.current"
        :page-sizes="[30, 50, 100]"
        :page-size="pageParams.size"
      >
      </el-pagination>
    </div>
    <el-dialog
      :title="updataStatus === 1 ? '新增合同' : '修改合同'"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      @close="clearnF('form')"
      width="30%"
    >
      <div v-if="dialogVisible" @click="showtypeAdd = false">
        <el-form
          :model="form"
          :rules="rules"
          label-width="100px"
          label-position="left"
          ref="form"
        >
          <el-form-item label="甲方" prop="firstParty">
            <el-input v-model="form.firstParty"></el-input>
          </el-form-item>
          <el-form-item label="联系电话" prop="phone">
            <el-input v-model="form.phone"></el-input>
          </el-form-item>
          <el-form-item label="地址" prop="address">
            <el-input v-model="form.address"></el-input>
          </el-form-item>
          <el-form-item label="合同编号" prop="contractNumber">
            <el-input v-model="form.contractNumber"></el-input>
          </el-form-item>
          <el-form-item label="合同类型" prop="contractType">
            <!-- <el-cascader
            class="form-item"
            v-model="form.contractType"
            :options="contractTypeDic"
            :props="contractTypeProps"
            :show-all-levels="true"
          ></el-cascader> -->
            <el-input
              @click.native.stop="showtypeAdd = true"
              v-model="form.contractType"
              placeholder="请选择"
              class="SearchInput"
              clearable
              @clear="clearTypeAdd"
              readonly
            >
              <template v-slot:suffix @click.stop="clearTypeAdd">
                <i
                  @click.stop="clearTypeAdd"
                  :class="
                    form.contractType
                      ? 'el-icon-circle-close'
                      : 'el-icon-caret-bottom'
                  "
                ></i>
              </template>
            </el-input>
            <div
              class="searchType1"
              @click.stop="showtypeAdd = true"
              v-if="showtypeAdd"
            >
              <div style="margin: 10px">
                <el-radio-group
                  v-model="form.contractTypeParent1"
                  @change="changeChildSearchAdd"
                >
                  <div v-for="item in options" :key="item.id">
                    <el-radio
                      class="radioList"
                      :label="item.id"
                      :key="item.id"
                      >{{ item.name }}</el-radio
                    >
                    <el-radio-group
                      v-model="form.contractTypeParent2"
                      @change="changeChildSearchAdd2"
                      style="margin-left: 20px"
                      :disabled="parentIDAdd1 != item.id ? true : false"
                      v-if="item.children && item.children.length > 0"
                    >
                      <div v-for="itema in item.children" :key="itema.id">
                        <el-radio
                          class="radioList"
                          :label="itema.id"
                          :key="itema.id"
                          >{{ itema.name }}</el-radio
                        >
                        <el-checkbox-group
                          @change="changeLabelSearchAdd"
                          v-model="searchContractTypeChildIdArrAdd"
                          style="margin-left: 20px"
                          :disabled="parentIDAdd2 != itema.id ? true : false"
                          v-if="itema.children && itema.children.length > 0"
                        >
                          <el-checkbox
                            v-for="itemc in itema.children"
                            :label="itemc.id"
                            :key="itemc.id"
                            style="width: 140px"
                            >{{ itemc.name }}</el-checkbox
                          >
                        </el-checkbox-group>
                      </div>
                    </el-radio-group>
                  </div>
                </el-radio-group>
              </div>
            </div>
          </el-form-item>
          <el-form-item label="合同附件类型" prop="fileType">
            <el-radio-group v-model="form.fileType">
              <el-radio
                v-for="(item, index) in fileTypeDic"
                :key="index"
                :label="item.code"
                @change="onFileType"
                >{{ item.label }}</el-radio
              >
            </el-radio-group>
          </el-form-item>
          <el-form-item label="合同附件" prop="contractAttachment">
            <el-upload
              action="#"
              v-if="!form.fileType"
              :auto-upload="true"
              :http-request="uploadImgContractAttachment"
              :on-change="beforHFhandleChangeImg"
              :on-remove="(file, list) => contractAttachmentDelete(file, list)"
              list-type="picture-card"
              accept=".jpg,.jpeg,.png,.gif,.bmp,.JPG,.JPEG,.PBG,.GIF,.BMP"
              :file-list="contractAttachment"
            >
              <div style="height: 20px">
                <img :src="uploadImg" />
              </div>
            </el-upload>
            <el-upload
              action="#"
              v-else
              :auto-upload="true"
              :http-request="uploadImgContractAttachment"
              :on-remove="(file, list) => contractAttachmentDelete(file, list)"
              :on-change="beforHFhandleChangeImg"
              accept=".pdf,.PDF"
              :file-list="contractAttachment"
            >
              <el-button>选择文件导入</el-button>
            </el-upload>
          </el-form-item>
          <el-form-item label="缴费佐证" prop="payProof">
            <el-upload
              action="#"
              list-type="picture-card"
              :http-request="uploadImgPayProof"
              :on-remove="payProofDelete"
              :on-change="payProofChangeImg"
              :auto-upload="true"
              accept=".jpg,.jpeg,.png,.gif,.bmp,.JPG,.JPEG,.PBG,.GIF,.BMP"
              :file-list="payProof"
            >
              <div style="height: 20px">
                <img :src="uploadImg" />
              </div>
            </el-upload>
          </el-form-item>
          <el-form-item label="补充描述" prop="signDescription">
            <el-input v-model="form.signDescription"></el-input>
          </el-form-item>
        </el-form>
        <div style="text-align: right">
          <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button
              type="primary"
              @click="onSumbitForm"
              :loading="btnLoading"
              >保 存</el-button
            >
          </span>
        </div>
      </div>
    </el-dialog>
    <el-dialog title="合同详细信息" :visible.sync="isShowContract">
      <el-dialog
        title="工单信息"
        :visible.sync="orderVisible"
        append-to-body
        width="30%"
      >
        <div class="smallTitle">工单信息</div>
        <div class="info-content">
          <el-row class="info-item">
            <span class="label">工单单号：</span>
            <span class="content-item">{{ orderInfo.orderCode }}</span>
          </el-row>
          <el-row class="info-item">
            <span class="label">用户类型：</span>
            <span class="content-item">{{ orderInfo.userType }}</span>
          </el-row>
          <el-row class="info-item">
            <span class="label">用气设备：</span>
            <span class="content-item">{{ orderInfo.equipment }}</span>
          </el-row>
          <el-row class="info-item">
            <span class="label">执行位置：</span>
            <span class="content-item">{{ orderInfo.address }}</span>
          </el-row>
          <el-row class="info-item" v-if="orderInfo.firstImageUrl">
            <span class="label">执行简图：</span>
            <span class="content-item">
              <el-image
                style="width: 100px; height: 100px"
                :src="orderInfo.firstImageUrl"
                :preview-src-list="orderInfo.imageUrl"
              >
              </el-image>
              <span v-if="orderVisible"
                >共{{ orderInfo.imageUrl.length }}张</span
              >
            </span>
          </el-row>
          <el-row class="info-item">
            <span class="label">测量员：</span>
            <span class="content-item">{{ orderInfo.realname }}</span>
          </el-row>
          <el-row class="info-item">
            <span class="label">创建时间：</span>
            <span class="content-item">{{ orderInfo.createTime }}</span>
          </el-row>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="orderVisible = false">关 闭</el-button>
        </span>
      </el-dialog>
      <div class="contrantInfo">
        <div class="smallTitle">合同信息</div>
        <div class="info-content">
          <el-row class="info-item">
            <el-col :span="8">
              <span class="label">合同来源：</span>
              <span class="content-item">{{
                contractInfo.contractFrom == "报装"
                  ? "用户" + contractInfo.contractFrom
                  : contractInfo.contractFrom
              }}</span>
            </el-col>
            <el-col :span="8" v-if="contractInfo.orderCode">
              <span class="label">报装单号：</span>
              <span class="content-item">{{ contractInfo.orderCode }}</span>
            </el-col>
            <el-col :span="8" v-if="contractInfo.orderCode">
              <span class="label">报装工单信息：</span>
              <!-- 改成按钮 -->
              <span class="content-item">
                <el-button
                  type="text"
                  @click="onShowOrderInfo(contractInfo.orderCode)"
                  >查看信息 ></el-button
                >
              </span>
            </el-col>
          </el-row>
          <el-row class="info-item">
            <el-col :span="8">
              <span class="label">甲方：</span>
              <span class="content-item">{{ contractInfo.firstParty }}</span>
            </el-col>
            <el-col :span="8">
              <span class="label">联系电话：</span>
              <span class="content-item">{{ contractInfo.phone }}</span>
            </el-col>
          </el-row>
          <el-row class="info-item">
            <el-col :span="24">
              <span class="label">联系地址：</span>
              <span class="content-item">{{ contractInfo.address }}</span>
            </el-col>
          </el-row>
          <el-row class="info-item">
            <el-col :span="24">
              <span class="label">合同类型：</span>
              <span class="content-item"
                >{{ contractInfo.contractTypeParent }}
                <span v-if="contractInfo.contractTypeChild"
                  >({{ contractInfo.contractTypeChild }})</span
                >
              </span>
            </el-col>
          </el-row>
          <el-row class="info-item">
            <el-col :span="24">
              <span class="label">合同编号：</span>
              <span class="content-item">{{
                contractInfo.contractNumber
              }}</span>
            </el-col>
          </el-row>
          <el-row class="info-item">
            <el-col :span="8">
              <span class="label">合同附件：</span>
              <span class="content-item">
                <el-image
                  v-if="contractInfo.fileType == 1"
                  style="width: 100px; height: 100px"
                  :src="firstImg"
                  @click="exportExcel(contractInfo)"
                >
                </el-image>
                <el-image
                  v-else
                  style="width: 100px; height: 100px"
                  :src="firstImg"
                  :preview-src-list="contractInfo.contractAttachment"
                >
                </el-image>
                <span v-if="isShowContract"
                  >共{{ contractInfo.contractAttachment.length }}张</span
                >
              </span>
            </el-col>
            <el-col :span="8">
              <span class="label">缴费佐证：</span>
              <span class="content-item">
                <el-image
                  style="width: 100px; height: 100px"
                  :src="firstImgPayPoof"
                  :preview-src-list="contractInfo.payProof"
                >
                </el-image>
                <span v-if="isShowContract"
                  >共{{ contractInfo.payProof.length }}张</span
                >
              </span>
            </el-col>
            <el-col :span="8"> </el-col>
          </el-row>
          <el-row class="info-item">
            <el-col :span="24">
              <span class="label">签约说明：</span>
              <span class="content-item">{{
                contractInfo.signDescription
              }}</span>
            </el-col>
          </el-row>
          <el-row class="info-item">
            <el-col :span="8">
              <span class="label">签约人：</span>
              <span class="content-item">{{ contractInfo.signRealname }}</span>
            </el-col>
            <el-col :span="8">
              <span class="label">签约时间：</span>
              <span class="content-item">{{ contractInfo.signTime }}</span>
            </el-col>
            <el-col :span="8"> </el-col>
          </el-row>
        </div>
        <div class="smallTitle">合同确认信息</div>
        <div class="info-content">
          <el-row class="info-item">
            <el-col :span="24">
              <img
                :src="lhimg"
                v-if="contractInfo.restoreConfirmStatus == '已确认'"
                class="configImg"
                alt=""
              />
              <img :src="garyimg" v-else class="configImg" alt="" />
              存档确认
            </el-col>
          </el-row>
          <el-row
            class="info-item"
            v-if="contractInfo.restoreConfirmStatus == '已确认'"
          >
            <!-- 站位 -->
            <el-col :span="1"></el-col>
            <el-col :span="7">
              <span class="label">档案号：</span>
              <span class="content-item">{{
                contractInfo.restoreFileNumber
              }}</span>
            </el-col>
            <el-col :span="8">
              <span class="label">确认人：</span>
              <span class="content-item">{{
                contractInfo.restoreConfirmRealname
              }}</span>
            </el-col>
            <el-col :span="8">
              <span class="label">确认时间：</span>
              <span class="content-item">{{
                contractInfo.restoreConfirmTime
              }}</span>
            </el-col>
          </el-row>
          <el-row class="info-item">
            <el-col :span="24">
              <img
                :src="lhimg"
                v-if="contractInfo.countisticsConfirmStatus == '已确认'"
                class="configImg"
                alt=""
              />
              <img :src="garyimg" v-else class="configImg" alt="" />
              计统确认
            </el-col>
          </el-row>
          <el-row
            class="info-item"
            v-if="contractInfo.countisticsConfirmStatus == '已确认'"
          >
            <!-- 站位 -->
            <el-col :span="1"></el-col>
            <el-col :span="7">
              <span class="label">确认人：</span>
              <span class="content-item">{{
                contractInfo.countisticsConfirmRealname
              }}</span>
            </el-col>
            <el-col :span="8">
              <span class="label">确认时间：</span>
              <span class="content-item">{{
                contractInfo.countisticsConfirmTime
              }}</span>
            </el-col>
            <el-col :span="8"> </el-col>
          </el-row>
          <el-row class="info-item">
            <el-col :span="24">
              <img
                :src="lhimg"
                v-if="contractInfo.financeConfirmStatus == '已确认'"
                class="configImg"
                alt=""
              />
              <img :src="garyimg" v-else class="configImg" alt="" />
              财务确认
            </el-col>
          </el-row>
          <el-row
            class="info-item"
            v-if="contractInfo.financeConfirmStatus == '已确认'"
          >
            <!-- 站位 -->
            <el-col :span="1"></el-col>
            <el-col :span="7">
              <span class="label">确认人：</span>
              <span class="content-item">{{
                contractInfo.financeConfirmRealname
              }}</span>
            </el-col>
            <el-col :span="8">
              <span class="label">确认时间：</span>
              <span class="content-item">{{
                contractInfo.financeConfirmTime
              }}</span>
            </el-col>
            <el-col :span="8"> </el-col>
          </el-row>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      title="图片预览"
      :visible.sync="dialogVisibleImg"
      width="100%"
      :close-on-click-modal="false"
    >
      <div><img :src="dialogImageUrl" width="100%" /></div>
    </el-dialog>
  </div>
</template>

<script>
import pageMixins from "@/utils/pageMixins";
import axios from "@/apis/contractApi";
import request from "axios";
import { getDict, selectDictListByParentCode } from "@/apis/commonType";

export default {
  mixins: [pageMixins],
  data() {
    return {
      date: [],
      pageParams: {
        current: 1,
        size: 100,
        signTime: null,
        contractConfirmStatus: null,
        contractType: null,
        firstPartyOrPhone: null,
        contractTypeParent2: null,
        startTime: null,
        endTime: null,
      }, // 分页查询表单

      contractFromDic: [
        { label: "报装", code: "1" },
        { label: "系统生成", code: "2" },
      ], //  合同来源字典
      searchContractTypeChildIdArr: [],
      searchContractTypeChildIdArrAdd: [],
      contractTypeDic: [], //  合同类型字典
      generalStatusDic: [
        { label: "未完成", value: 0 },
        { label: "已完成", value: 1 },
      ], //  状态字典
      contractConfirmStatusDic: [
        { label: "待确认", code: 1 },
        { label: "确认中", code: 2 },
        { label: "确认完成", code: 3 },
      ],
      tableHeader: [
        { label: "合同来源", property: "contractFrom", width: 100 },
        { label: "合同编号", property: "contractNumber" },
        { label: "合同类型", property: "contractType" },
        { label: "甲方", property: "firstParty" },
        { label: "联系电话", property: "phone" },
        { label: "地址", property: "address" },
        { label: "签约人", property: "signRealname" },
        { label: "签约时间", property: "signTime", width: 150 },
        { label: "确认状态", property: "contractConfirmStatus", width: 90 },
        { label: "完成状态", property: "contractCompleteStatus", width: 90 },
      ], //  表头数据
      tableData: [], //  表格数据
      dialogVisible: false, //  弹窗开关
      tableLoading: false, //  表格loading
      updataStatus: 1, // 表单开关 1 新增 2 修改
      form: {
        firstParty: "", //  甲方
        phone: "", //  联系方式
        address: "", //  地址
        contractNumber: "", //  合同编号
        contractType: "", //  合同类型
        fileType: 0,
        contractAttachment: [], //  合同附件
        payProof: [], //  缴费凭证
        signDescription: "", //
        contractTypeChild: null,
        contractTypeParent: null,
        contractTypeParent1: null,
        contractTypeParent2: null,
      }, //  表单
      showtypeAdd: false,
      contractInfo: {}, //  合同详情
      rules: {
        firstParty: [
          { required: true, message: "请输入", trigger: "blur" },
          { max: 100, message: "超出限制", trigger: ["change", "blur"] },
        ],
        contractType: [
          { required: true, message: "请选择", trigger: "change" },
        ],
        phone: [
          { required: true, message: "请输入", trigger: "blur" },
          {
            pattern: /^[1][3,4,5,6,7,8,9][0-9]{9}$/,
            message: "手机格式错误",
            trigger: "blur",
          },
        ],
        address: [{ required: true, message: "请输入", trigger: "blur" }],
        contractNumber: [{ max: 100, message: "超出限制", trigger: ["blur"] }],
        contractAttachment: [
          { required: true, message: "请上传", trigger: "blur" },
        ],
        payProof: [{ required: true, message: "请上传", trigger: "blur" }],
        signDescription: [
          { max: 100, message: "超出限制", trigger: ["change", "blur"] },
        ],
      }, //  校验规则
      fileTypeDic: [
        { label: "图片", code: 0 },
        { label: "pdf", code: 1 },
      ], //  文件类型字典
      isShowContract: false, //  详情弹窗开关.
      contractTypeProps: {
        label: "name",
        children: "children",
        value: "code",
        emitPath: false,
      }, //  合同类型级联配置
      firstAttachment: "", //  首张照片
      lhimg: require("@/assets/lhcomfirm.png"),
      garyimg: require("@/assets/comfirm.png"),
      baseUrl: "", //  上传请求地址
      uploadImg: require("@/assets/image/upload.png"), //  上传图片默认图标
      dialogVisibleImg: false, //  图片预览弹窗
      dialogImageUrl: "", //  预览图片的图片地址
      contractAttachment: [], //  合同附件列表
      payProof: [], //  缴费凭证列表
      btnLoading: false, //  按钮loading
      orderVisible: false, //  工单信息开关
      orderInfo: {}, //  工单信息
      firstImg: "", //  合同附件第一张图片
      firstImgPayPoof: "", //  缴费佐证第一张图片
      options: [],
      showtype: false,
      isCheckBokS: true,
      parentID1: null,
      parentID2: null,
      parentIndex: 0,
      parentName: "",
      parentName2: "",
      parentIDAdd1: null,
      parentIDAdd2: null,
      parentIndexAdd: 0,
      parentNameAdd2: "",
      parentNameAdd: "",
      dom: null,
      style: {
        left: 0,
      },
      left: 0,
    };
  },
  mounted() {
    this.baseUrl = request.defaults.baseURL;
    this.getOption();
    //  加载列表
    this.loadList();
    this.left = this.$refs.viewContract.offsetLeft + 90;
    this.style.left = this.left + "px";
    this.dom = this.$refs.viewBox;
    this.dom.addEventListener("scroll", this.handleFun);
  },
  beforeDestroy() {
    if (this.dom) this.dom.removeEventListener("scroll", this.handleFun);
  },
  methods: {
    handleFun() {
      this.style.left = this.left - this.dom.scrollLeft + "px";
    },
    getOption() {
      selectDictListByParentCode({
        parentCode: "hetongleixing",
        level: 4,
      }).then((res) => {
        if (res.code == 200) {
          this.options = res.data;
        }
      });
    },
    clearType() {
      if (this.pageParams.contractType) {
        this.pageParams.contractTypeParent1 = "";
        this.pageParams.contractTypeParent2 = null;
        this.searchContractTypeChildIdArr = [];
        this.pageParams.contractTypeChild = null;
        this.isCheckBokS = true;
        this.parentID2 = null;
        this.parentID1 = null;
        this.pageParams.contractType = "";
        this.showtype = false;
      }
    },
    clearTypeAdd() {
      if (this.form.contractType) {
        this.form.contractTypeParent1 = "";
        this.form.contractTypeParent2 = null;
        this.form.contractTypeChild = null;
        this.form.contractType = "";
        this.searchContractTypeChildIdArrAdd = [];
        this.parentIDAdd2 = null;
        this.parentIDAdd1 = null;
        this.showtypeAdd = false;
      }
    },
    changeChildSearch(val) {
      this.parentID1 = val;
      this.parentID2 = null;
      this.searchContractTypeChildIdArr = [];
      this.pageParams.contractTypeParent2 = null;
      this.options.forEach((el, index) => {
        if (el.id == val) {
          this.pageParams.contractType = el.name;
          this.parentIndex = index;
          this.parentName = el.name;
        }
      });
    },
    changeChildSearchAdd(val) {
      this.parentIDAdd1 = val;
      this.parentIDAdd2 = null;
      this.searchContractTypeChildIdArrAdd = [];
      this.form.contractTypeParent2 = null;
      this.options.forEach((el, index) => {
        if (el.id == val) {
          this.form.contractType = el.name;
          this.parentIndexAdd = index;
          this.parentNameAdd = el.name;
        }
      });
    },
    changeChildSearch2(val) {
      let { parentIndex } = this;
      this.parentID2 = val;
      this.searchContractTypeChildIdArr = [];
      this.options[parentIndex].children.forEach((el) => {
        if (el.id == val) {
          this.pageParams.contractType = this.parentName + "-" + el.name;
          this.parentName2 = this.pageParams.contractType;
        }
      });
    },
    changeChildSearchAdd2(val) {
      let { parentIndexAdd } = this;
      this.parentIDAdd2 = val;
      this.searchContractTypeChildIdArrAdd = [];
      this.options[parentIndexAdd].children.forEach((el) => {
        if (el.id == val) {
          this.form.contractType = this.parentNameAdd + "-" + el.name;
          this.parentNameAdd2 = this.form.contractType;
        }
      });
    },
    changeLabelSearch(val) {
      let { parentIndex } = this;
      var namekey = {};
      this.options[parentIndex].children.forEach((el) => {
        if (el.id == this.pageParams.contractTypeParent2) {
          el.children.forEach((item) => {
            namekey[item.id] = item.name;
          });
          var name = [];
          val.forEach((items) => {
            name.push(namekey[items]);
          });
          let showname = name.toString();
          if (showname) {
            this.pageParams.contractType =
              this.parentName2 + "(" + showname + ")";
          } else {
            this.pageParams.contractType = this.parentName2;
          }
        }
      });
    },
    changeLabelSearchAdd(val) {
      let { parentIndexAdd } = this;
      var namekey = {};

      this.options[parentIndexAdd].children.forEach((el) => {
        if (el.id == this.form.contractTypeParent2) {
          el.children.forEach((item) => {
            namekey[item.id] = item.name;
          });
          var name = [];
          val.forEach((items) => {
            name.push(namekey[items]);
          });

          let showname = name.toString();
          if (showname) {
            this.form.contractType = this.parentNameAdd2 + "(" + showname + ")";
          } else {
            this.form.contractType = this.parentNameAdd2;
          }
        }
      });
    },
    /**加载表格数据 */
    async loadList() {
      this.pageParams.contractTypeChild = null;
      if (this.searchContractTypeChildIdArr.length > 0) {
        this.pageParams.contractTypeChild =
          this.searchContractTypeChildIdArr.toString();
      }
      this.pageParams.contractTypeParent = this.pageParams.contractTypeParent1;
      if (this.pageParams.contractTypeParent2) {
        this.pageParams.contractTypeParent +=
          "," + this.pageParams.contractTypeParent2;
      }
      this.tableLoading = true;
      let { pageParams } = this;
      let res = await axios.getTableData(pageParams);
      res.data.records.forEach((el) => {
        if (el.contractFrom === "报装") el.contractFrom = "用户报装";
      });

      this.tableData = res.data.records;
      this.total = res.data.total;
      this.tableLoading = false;
    },

    /**
     * 点击新增合同按钮
     */
    onAddNewContract() {
      this.dialogVisible = true;
      this.updataStatus = 1;
    },
    /**
     * 点击表格编辑按钮
     */
    async onUpdataRow(row) {
      this.updataStatus = 2;
      let { id: contractId } = row;
      let res = await axios.getContractInfo({ contractId });
      this.form = JSON.parse(JSON.stringify(res.data));
      this.$set(this.form, "contractTypeChild", null);
      this.$set(this.form, "contractTypeParent", null);
      this.$set(this.form, "contractTypeParent1", null);
      this.$set(this.form, "contractTypeParent2", null);
      this.$set(this.form, "contractType", null);
      this.form.contractAttachment = res.data.contractAttachment.split(",");
      this.contractAttachment = this.form.contractAttachment.map((e) => {
        return { url: e, type: "success", name: e };
      });
      let contractTypeChildIds = [],
        contractTypeParentIds = [];
      if (res.data.contractTypeChildIds)
        contractTypeChildIds = res.data.contractTypeChildIds
          .split(",")
          .map((el) => parseInt(el));
      if (res.data.contractTypeParentIds)
        contractTypeParentIds = res.data.contractTypeParentIds
          .split(",")
          .map((el) => parseInt(el));

      if (contractTypeParentIds.length) {
        this.changeChildSearchAdd(contractTypeParentIds[0]);
        this.form.contractTypeParent1 = contractTypeParentIds[0];
      }
      if (contractTypeParentIds.length > 1) {
        this.changeChildSearchAdd2(contractTypeParentIds[1]);
        this.form.contractTypeParent2 = contractTypeParentIds[1];

        if (res.data.contractTypeChild) {
          this.form.contractType =
            this.parentNameAdd2 + "(" + res.data.contractTypeChild + ")";
        }
      }
      this.searchContractTypeChildIdArrAdd = contractTypeChildIds;

      let { contractAttachment } = this;
      //  判断文件类型
      let reg = /\.(pdf|PDF)$/;
      let fileType = reg.test(contractAttachment[0].url) ? 1 : 0;
      this.$set(this.form, "fileType", fileType);
      this.form.payProof = res.data.payProof.split(",");
      this.payProof = this.form.payProof.map((e) => {
        return { url: e, type: "success" };
      });
      this.dialogVisible = true;
    },
    /**
     * 点击表格查看按钮
     */
    async onShowContract(row) {
      let { id: contractId } = row;
      let res = await axios.getContractInfo({ contractId });
      this.contractInfo = res.data;
      res.data.contractAttachment = res.data.contractAttachment.split(",");
      res.data.payProof = res.data.payProof.split(",");
      //  判断类型
      let reg = /\.(pdf|PDF)$/;
      //  pdf图片不可预览手动加一个图片预览
      this.firstImg = reg.test(res.data.contractAttachment[0])
        ? require("@/assets/image/pdf.jpg")
        : res.data.contractAttachment[0];
      let fileType = reg.test(res.data.contractAttachment[0]) ? 1 : 0;
      this.$set(this.contractInfo, "fileType", fileType);
      this.firstImgPayPoof = res.data.payProof[0];
      this.isShowContract = true;
    },
    /**
     * 格式化表单
     */
    clearnF(form) {
      this.form = {
        firstParty: "", //  甲方
        phone: "", //  联系方式
        address: "", //  地址
        contractNumber: "", //  合同编号
        contractType: "", //  合同类型
        fileType: 0,
        contractAttachment: [], //  合同附件
        payProof: [], //  缴费凭证
        signDescription: "", //
        contractTypeChild: null,
        contractTypeParent: null,
        contractTypeParent1: null,
        contractTypeParent2: null,
      }; //  表单
      this.contractAttachment = [];
      this.payProof = [];
      this.searchContractTypeChildIdArrAdd = [];
      this.showtypeAdd = false;
      this.parentIDAdd1 = null;
      this.parentIDAdd2 = null;
      this.parentIndexAdd = 0;
      this.parentNameAdd2 = "";
      this.parentNameAdd = "";
      this.$refs.form.resetFields();
    },
    /**
     * 点击保存按钮
     */
    async onSumbitForm() {
      //  按钮loading时阻止继续防止多次发送
      if (this.btnLoading) return;
      //  校验表单
      let valid = await this.$refs.form.validate();
      if (!valid) return;
      //  开启loading
      this.btnLoading = true;
      let { updataStatus, form } = this;
      //  深拷贝防止污染源数据
      let obj = JSON.parse(JSON.stringify(form));
      obj.contractTypeChild = null;
      if (this.searchContractTypeChildIdArrAdd.length > 0) {
        obj.contractTypeChild = this.searchContractTypeChildIdArrAdd.toString();
      }
      obj.contractTypeParent = obj.contractTypeParent1;
      if (obj.contractTypeParent2) {
        obj.contractTypeParent += "," + obj.contractTypeParent2;
      }
      // let {
      //   firstParty,
      //   phone,
      //   address,
      //   contractNumber,
      //   contractType,
      //   fileType,
      //   contractAttachment,
      //   payProof,
      //   signDescription,
      //   id,
      // } = obj;
      console.log(obj);
      let data = {};
      Object.assign(data, obj);
      //  格式处理
      data.contractAttachment = data.contractAttachment.join(",");
      data.payProof = data.payProof.join(",");
      if (updataStatus != 1) {
        delete data.contractCompleteStatus;
        delete data.contractConfirmStatus;
        delete data.contractFrom;
        delete data.countisticsConfirmStatus;
        delete data.financeConfirmStatus;
        delete data.restoreConfirmStatus;
      }
      //  请求方法
      try {
        await axios[updataStatus == 1 ? "addNewContract" : "updateContract"](
          data
        );
        let message = updataStatus == 1 ? "新增成功" : "修改成功";
        this.loadList();
        this.$message.success(message);
        this.dialogVisible = false;
      } catch (e) {}
      //  解除loading
      this.btnLoading = false;
    },
    exportExcel(data) {
      data.contractAttachment.forEach((el, index) => {
        let x = new XMLHttpRequest();
        x.open("GET", el, true);
        x.responseType = "blob";
        x.onload = function (e) {
          let url = window.URL.createObjectURL(x.response);
          let a = document.createElement("a");
          a.href = url;
          a.download = data.contractNumber + "附件" + index + 1; //下载后的文件名
          a.click();
        };
        x.send();
      });
    },
    onFileType() {
      this.form.contractAttachment = [];
      this.contractAttachment = [];
    },
    /**
     * 合同附件上传至阿里云
     */
    async uploadImgContractAttachment(files) {
      let uploadObj = new FormData();
      uploadObj.append("file", files.file);
      let res = await axios.uploadImg(uploadObj);
      this.form.contractAttachment.push(res.data.url);
    },
    /**
     * 缴费佐证上传至阿里云
     */
    async uploadImgPayProof(files) {
      let uploadObj = new FormData();
      uploadObj.append("file", files.file);
      let res = await axios.uploadImg(uploadObj);
      this.form.payProof.push(res.data.url);
    },
    beforHFhandleChangeImg(file, fileList) {
      this.contractAttachment = fileList;
    },
    /**
     * 合同附件删除文件
     */
    contractAttachmentDelete(file, fileList) {
      let index = this.getArrayIndex(this.contractAttachment,file);
      this.contractAttachment.splice(index,1)
      this.form.contractAttachment.splice(index,1)
    },
    payProofChangeImg(file, fileList){
      this.payProof = fileList;
    },
    getArrayIndex(arr, obj) {
      var i = arr.length;
      while (i--) {
        if (arr[i] === obj) {
          return i;
        }
      }
      return -1;
    },
    /**
     * 缴费佐证删除文件
     */
    payProofDelete(file) {
      // this.form.payProof = this.form.payProof.filter((e) => e !== file.url);
      let index = this.getArrayIndex(this.payProof,file);
      this.payProof.splice(index,1)
      this.form.payProof.splice(index,1)
    },
    //  查询工单信息
    async onShowOrderInfo(orderCode) {
      try {
        let res = await axios.getOrderInfo({ orderCode });
        if (res.data.imageUrl) {
          res.data.imageUrl = res.data.imageUrl.split(",");
          res.data["firstImageUrl"] = res.data.imageUrl.length
            ? res.data.imageUrl[0]
            : "";
        } else {
          res.data.imageUrl = [];
          res.data["firstImageUrl"] = "";
        }

        this.orderInfo = res.data;
        this.orderVisible = true;
      } catch (e) {}
    },
    //  完成合同
    async onCompleteContract(row) {
      try {
        await axios.completeContract({ contractId: row.id });
        this.$message.success("合同已完成");
        this.loadList();
      } catch (e) {}
    },
    /**设置日期 */
    onSetDate() {
      let { date } = this;
      if (date) {
        this.pageParams.startTime = date[0];
        this.pageParams.endTime = date[1];
      } else {
        this.pageParams.startTime = null;
        this.pageParams.endTime = null;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.contain1 {
  padding: 20px 15px !important;
  position: relative;
}
.contain1 {
  .searchBox {
    .searchBoxForm {
      display: flex;
      align-items: center;
      .searchItem {
        margin-bottom: 0px;
      }
    }
  }
}
.contain2 {
  .tableTool {
    display: flex;
    .infoTitle {
      flex: 1;
      font-size: 18px;
    }
    .rightButtonTool {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      margin-bottom: 10px;
    }
  }
}
.smallTitle {
  font-size: 16px;
  margin-bottom: 15px;
  color: #303133;
  &::before {
    content: "";
    display: inline-block;
    height: 16px;
    width: 3px;
    margin-right: 5px;
    vertical-align: middle;
    background-color: #1082ff;
  }
}
.info-content {
  padding-left: 30px;
  .info-item {
    margin-bottom: 20px;
  }
  .label {
    color: #909399;
  }
  .content-item {
    color: #444444;
  }
  .configImg {
    width: 16px;
    height: 16px;
    vertical-align: middle;
  }
}
.form-item {
  width: 100%;
}
.searchType1 {
  width: 210px;
  box-shadow: 1px 1px 5px #888888;
  position: absolute;
  z-index: 100;
  background-color: #fff;
}
.searchType {
  width: 210px;
  box-shadow: 1px 1px 5px #888888;
  position: absolute;
  z-index: 100;
  background-color: #fff;
  left: 905px;
}
.SearchBar {
  /* border: 1px solid #ecedf1; */
  border-radius: 5px;
  margin: 10px;
  padding-top: 1%;
  background-color: #ffffff;
}
.SearchInput {
  width: 100%;
}
.radioList {
  display: block;
  margin: 10px 0px;
}
::v-deep.viewContract .el-form-item__content {
  position: initial;
}
</style>